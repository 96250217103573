<template>
  <van-form @submit="onSubmit" validate-trigger='onSubmit'>
    <van-field name="async" label="同步入库">
      <template #input>
        <van-switch v-model="form.async" size="20" @change='onSwitch' />
      </template>
    </van-field>
    <div v-if='form.async'>
      <van-field v-model="form.warehousing_sn" name="warehousing_sn" label="入库单号" placeholder="入库单号" readonly required
        :rules="[{ required: true, message: '入库单号不能为空' }]" />
      <van-field v-model="form.warehouse_name" is-link readonly name="warehouse_name" label="入库仓库" placeholder="请选择入库仓库"
        required :rules="[{ required: true, message: '请选择入库仓库' }]"
        @click="()=>{if(isDisabled){return} warehouseShow = true}" />
      <van-field name="use_product_batch" label="生产批次入库">
        <template #input>
          <van-switch v-model="form.use_product_batch" size="20" active-color='#5B9DFF' :disabled='isDisabled' />
        </template>
      </van-field>
      <van-field v-model="form.product_batch_name" is-link readonly name="product_batch_name" label="生产批次"
        placeholder="请选择生产批次" required :rules="[{ required: true, message: '请选择生产批次' }]"
        @click="()=>{if(isDisabled){return} batchShow = true}" v-if='form.use_product_batch' />
      <van-field v-model="form.product_name" is-link readonly name="product_name" label="商品名称" placeholder="请选择商品"
        required :rules="[{ required: true, message: '请选择商品' }]"
        @click="()=>{if(isDisabled){return} goodsShow = true}" />
    </div>
    <van-field label="包装规格" label-class='tw-flex tw-items-center' required>
      <template #input>
        <span class="tw-inline-block width-30">1拖</span>
        <van-field class="tw-bg-gray-300 tw-bg-opacity-30 tw-rounded-sm width-90" v-model="form.code_specs_middle" type="digit" placeholder="包装规格"
          :rules="[{ required: true, message: '请填写' }]" @update:model-value="big_code='';middle_code=[];small_code=[];dataList=[];code_type='BIG'"></van-field>
        <span class="tw-inline-block width-30 tw-text-center">拖</span>
        <van-field class="tw-bg-gray-300 tw-bg-opacity-30 tw-rounded-sm width-90" v-model="form.code_specs_small" type="digit" placeholder="包装规格"
          :rules="[{ required: true, message: '请填写' }]" @update:model-value="big_code='';middle_code=[];small_code=[];dataList=[];code_type='BIG'"></van-field>
      </template>
    </van-field>
    <van-field v-model="form.code_sn" name="code_sn" label="二维码" placeholder="二维码" required
      :rules="[{ required: true, message: '请填写二维码' }]" label-class='tw-flex tw-items-center'>
      <template #button>
        <van-button color='#5B9DFF' type="primary" size="small" class="m-r-10" native-type="submit">添加</van-button>
        <van-button size="small" :disabled='!big_code && !middle_code.length&& !small_code.length' @click='withdraw'>撤回</van-button>
      </template>
    </van-field>
    <div class="s_bf m-t-10 p-15">
      <p class="tw-text-center fz-16 s_font_b">请扫描{{code_type == 'BIG' ? '大标' : (code_type == 'MID' ? '中标' : '小标')}}</p>
      <p class="fz-14 m-t-15">大标：{{big_code}}</p>
      <div class="fz-14 m-t-15 s_flex_ali">
        <p class="width-42">中标：</p>
        <p class="small_code_box width85">
          <span v-for="(item,index) in middle_code" :key='index'>{{item}}<span class="dh">、</span></span>
        </p>
      </div>
      <div class="fz-14 m-t-15 s_flex_ali">
        <p class="width-42">小标：</p>
        <p class="small_code_box width85">
          <span v-for="(item,index) in small_code" :key='index'>{{item}}<span class="dh">、</span></span>
        </p>
      </div>
    </div>
  </van-form>
  <!-- 选择仓库 -->
  <van-popup v-model:show="warehouseShow" position="bottom" round safe-area-inset-bottom>
    <selectWarehouse :showChild='1' @cancel='warehouseShow = false' @confirm='warehouseConfirm'></selectWarehouse>
  </van-popup>
  <!-- 选择商品 -->
  <van-popup v-model:show="goodsShow" position="bottom" round safe-area-inset-bottom closeable
    :style="{ maxHeight: '80%'}">
    <selectGoods @select='goodsConfirm'></selectGoods>
  </van-popup>
  <!-- 选择批次 -->
  <van-popup v-model:show="batchShow" position="bottom" round safe-area-inset-bottom closeable
    :style="{ maxHeight: '80%'}">
    <selectGoodBatch @select='batchConfirm'></selectGoodBatch>
  </van-popup>
  <!-- 关联结果 -->
  <van-popup v-model:show="relationShow" :close-on-click-overlay='false' round :style="{ width: '80%'}">
    <div class="p-20">
      <p class="fz-16 s_font_b tw-text-center" :class='relationResult==0 ? "p-t-20 p-b-20":""'>{{relationTitle}}</p>
      <p class="fz-14 tw-text-center m-t-10" v-if='relationMsg'>原因：{{relationMsg}}</p>
      <div class="m-t-20 s_flex_center" v-if='relationResult == 1'>
        <!-- 重新上传：重新上传关联信息 -->
        <van-button block color='#5B9DFF' type="primary" class="width45 m-r-15" @click='relation();relationMsg="";relationShow=false'>重新上传</van-button>
        <!-- 重新关联：退出弹窗，清空扫码数据 -->
        <van-button block class="width45" @click='relationMsg="";big_code="";middle_code=[];small_code=[];relationShow=false;code_type="BIG"'>重新关联</van-button>
      </div>
      <div class="m-t-20 s_flex_center" v-if='relationResult == 2'>
        <!-- 重新入库：再次执行入库操作 -->
        <van-button block color='#5B9DFF' type="primary" class="width45 m-r-15" @click='warehousingSubmit();relationMsg="";relationShow=false'>重新入库</van-button>
        <!-- 仅关联不入库：退出弹窗，二维码保持关联状态，但大标没有入库，清空扫码数据 -->
        <van-button block class="width45" @click='relationTitle = "关联成功";relationMsg = "";relationResult = 3'>仅关联不入库</van-button>
      </div>
      <div class="m-t-20 s_flex_center" v-if='relationResult == 3'>
        <!-- 不同步入库：点击后返回页面，清空扫码数据。同步入库：重新创建入库单，批次，仓库，商品数据解除锁定 -->
        <van-button block color='#5B9DFF' type="primary" class="width45" @click='continueRelation'>继续关联</van-button>
      </div>
    </div>
  </van-popup>
  <audioPlay ref="audios" />
</template>
<script>
  import { ref, reactive, toRefs } from "@vue/reactivity";
  import { useWare, useGoods, useBatch } from "@/hooks/function.js";
  import { Toast } from 'vant';
  import { iscode } from '@/utils/app.js';
  import { setInWarehouse, getInWarehouseSn, setAssociated, getAssociatedCode } from '@/api/inWarehouse.js';
  let defForm = {
    async: false,
    warehousing_sn: '',
    warehouse_id: null,
    position_id: null,
    use_product_batch: 0,
    product_batch_id: '',
    product_id: '',
    product_sku_sn: '',
    scan_type: 1,
    code_sn: "",
    code_specs_middle: null,
    code_specs_small: null
  };
  export default {
    setup() {
      const form = ref({ ...defForm });
      const audios=ref('');
      const state = reactive({
        big_code: '', //页面大标
        middle_code: [], //页面中标
        small_code: [], //页面小标
        dataList:[],
        code_type:'BIG',
        isDisabled: false,
        relationShow:false, //是否显示关联信息弹窗
        relationTitle:'二维码关联中，请稍后...',
        relationMsg:'', //关联失败或者入库失败原因
        relationResult:0, //关联结果：1 关联失败 2 入库失败 3 入库成功
      })
      // 获取单号
      const getSn = async () => {
        let result = await getInWarehouseSn().then(res => res.data).catch(error => error)
        if (iscode(result)) {
          form.value.warehousing_sn = result.data.sn;
        } else {
          Toast.fail(result.msg);
        }
      }
      //如果同步，获取单号
      const onSwitch = (e) => {
        if (e) {
          getSn();
        }
      }
      //仓库选择
      const { warehouseShow, warehouseConfirm } = useWare(function (data) {
        form.value.warehouse_id = data[0].id;
        form.value.position_id = data[1] ? data[1].id : 0;
        form.value.warehouse_name = data[0].name + (data[1] ? '/' + data[1].name : '');
        state.warehouseShow = false
      });
      //商品选择
      const { goodsShow, goodsConfirm } = useGoods(function (data) {
        form.value.product_id = data.id;
        form.value.product_name = (data.product_sku_name ? data.product_sku_name : '单规格') + ' - ' + data.product_name;
        form.value.product_sku_sn = data.product_sku_sn || 0;
        state.goodsShow = false
      });
      //批次选择
      const { batchShow, batchConfirm } = useBatch(function (data) {
        form.value.product_id = data.product_id;
        form.value.product_name = data.product_name;
        form.value.product_sku_sn = data.product_sku_sn || 0;
        form.value.product_batch_id = data.id;
        form.value.product_batch_name = data.batch_name;
        state.batchShow = false
      });
      //计算当时扫的什么标
      const getCodeType = (lng) => {
        var calculationLng = lng - 1;
        // 计算每多少个一组
        var group = parseInt(form.value.code_specs_small) + 1;
        // 计算当前位置在第几组
        var number = Math.ceil(calculationLng / group);
        // 计算 当前在 这一组的第几位
        var result = (calculationLng % (number * group)) % group;
        var position = result == 0 || isNaN(result) ? "MID" : "SMALL";
        return {group,number,result,position};
      }
      //撤回
      const withdraw = () =>{
        state.dataList.pop();
        var code_data = getCodeType(state.dataList.length);
        state.code_type = code_data.position;
        if(!state.middle_code.length){
          state.code_type = 'BIG'
        }
        if(state.code_type == 'SMALL'){
          state.small_code.pop();
        }else if(state.code_type == 'MID'){
          state.middle_code.pop();
        }else{
          state.big_code = ''
        }
      }
      //添加（查询）
      const onSubmit = async () => {
        var index = state.dataList.findIndex(v => v == form.value.code_sn)
        if (index != -1) {
          Toast.fail('重复扫码！');
          form.value.code_sn = '';
          audios.value.error()
          return
        }
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await getAssociatedCode({ code_sn: form.value.code_sn }).then(res => res.data).catch(error => error)
        Toast.clear();
        if (iscode(result)) {
          if(state.code_type == 'BIG' && result.data.code_type != 1){
            Toast.fail('请扫描大标！');
            form.value.code_sn = ''
            audios.value.error()
            return
          }
          if(state.code_type == 'MID' && result.data.code_type != 2){
            Toast.fail('请扫描中标！');
            form.value.code_sn = ''
            audios.value.error()
            return
          }
          if(state.code_type == 'SMALL' && result.data.code_type != 3){
            Toast.fail('请扫描小标！');
            form.value.code_sn = ''
            audios.value.error()
            return
          }

          if (result.data.all_code_data.length != 1) {
            Toast.fail('二维码是套标，无法关联！');
            form.value.code_sn = ''
            audios.value.error()
            return
          } else {
            state.dataList.push(form.value.code_sn);
            state.big_code = state.dataList[0];
            var length = 1+Number(form.value.code_specs_middle)+form.value.code_specs_middle*form.value.code_specs_small;
            if(state.dataList.length == length){
              state.relationShow = true
              state.small_code.push(form.value.code_sn) //长度相等就意味扫码数量足够了，但是在这里return了，最后一个码没拼上，所以要在这里做最后一个码的拼接操作
              form.value.code_sn = '';
              state.isDisabled = form.value.async;
              relation();
              return
            }
            var code_data = getCodeType(state.dataList.length);
            state.code_type = code_data.position;
            if(state.dataList.length > 1){
              if(code_data.result == 1){
                state.middle_code.push(form.value.code_sn)
              }else{
                state.small_code.push(form.value.code_sn)
              }
            }
            form.value.code_sn = '';
            state.isDisabled = form.value.async;
            audios.value.pass()
          }
        } else {
          Toast.fail(result.msg);
        }
      }
      //数组分组
      const group = (array, subGroupLength) => {
        let index = 0;
        let newArray = [];
        while(index < array.length) {
            newArray.push(array.slice(index, index += subGroupLength));
        }
        return newArray;
      }
      //码关联
      const relation = async () => {
        var group_codes = [];
        var smallArr = group(state.small_code,form.value.code_specs_small)
        smallArr.map((v,key) => {
          v.map((s) => {
            group_codes.push({ big_code: state.big_code, middle_code: state.middle_code[key], small_code: s })
          })
        })
        let result = await setAssociated({ group_type: 3, group_codes: group_codes }).then(res => res.data).catch(error => error)
        if (iscode(result)) {
          if (form.value.async) {
            state.relationTitle = '关联成功，大标入库中...'
            state.relationMsg = ''
            state.relationResult = 0
            warehousingSubmit();
          }else{
            state.relationTitle = '关联成功'
            state.relationMsg = ''
            state.relationResult = 3
            audios.value.success()
          }
        } else {
          state.relationTitle = '二维码有误，关联失败'
          state.relationMsg = result.msg
          state.relationResult = 1
          audios.value.error()
        }
      }
      //入库
      const warehousingSubmit = async() => {
        let result = await setInWarehouse({ ...form.value,code_sn:state.big_code}).then(res => res.data).catch(error => error)
        if (iscode(result)) {
          state.relationTitle = '关联成功'
          state.relationMsg = ''
          state.relationResult = 3
          state.isDisabled = true
          audios.value.success()
				}else{
          state.relationTitle = '关联成功，但大标入库错误'
          state.relationMsg = result.msg
          state.relationResult = 2
          audios.value.error()
				}
      }
      //继续关联(清空数据)
      const continueRelation = () =>{
        state.big_code = '';
        state.middle_code = [];
        state.small_code = [];
        state.relationTitle = '二维码关联中，请稍后...';
        state.relationMsg = '';
        state.relationResult = 0;
        state.relationShow = false;
        state.code_type = 'BIG'
        state.isDisabled = form.value.async
        if(form.value.async){
          getSn();
        }
      }
      return {
        ...toRefs(state),
        form,
        warehouseShow,
        warehouseConfirm,
        goodsShow,
        goodsConfirm,
        batchShow,
        batchConfirm,
        onSwitch,
        onSubmit,
        withdraw,
        warehousingSubmit,
        relation,
        audios,
        continueRelation
      };
    },
  };
</script>
<style>
  .small_code_box span:last-child .dh {
    display: none;
  }
</style>
